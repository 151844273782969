import './main.scss'
// media
import analitika_dtp_video from './pics/video/dtp.mp4';
import ais_ms_video from './pics/video/ais_ms.mp4';
import its_tula_video from './pics/video/its_tula.mp4';

const buttons = [
    {
        text: "Аналитика ДТП",
        type: 'linked',
        link: "https://niikeeper.com/accidents/",
        media: {
            type: 'video',
            src: analitika_dtp_video
        }
    },
    {
        text: "АИС МС",
        type: 'linked',
        link: "http://mstest.mgtniip.ru/",
        media: {
            type: 'video',
            src: ais_ms_video
        }
    },
    {
        text: "ИТС г. Тула",
        type: 'linked',
        link: "https://tula.mostransproject.ru",
        media: {
            type: 'video',
            src: its_tula_video
        }
    }

]

const renderMedia = function (type, src) {
    if (type === 'img') {
        return `<img src="${src}" alt="gif" class="media-item">`
    }

    if (type === 'video') {
        return `<video class="media-item" muted autoplay loop>
								<source src="${src}" type="video/mp4">
                            </video>`
    }
};

const renderExpansionLinks = function (expansion) {
    let arr = [];
    expansion.forEach(button => {
        let buttonType = button.type;
        let expansionLink = `<div class="expansion__link" data-type="${buttonType}" data-link="${button.link}">
								<span class="expansion__text">${button.text}</span>
                            </div>`;

        arr.push(expansionLink);
    });
    return arr;
}

const renderButtonInner = function (button) {
    let media = "";
    let expansion = "";
    let textSize = "";
    let buttonType = button.type;
    let dataAtr = '';

    if (buttonType === 'linked') {
        dataAtr = ` data-link="${button.link}"`
    }

    if (button.media) {
        let mediaInnerHTML = renderMedia(button.media.type, button.media.src);
        media = `<div class="link-button__media">
							${mediaInnerHTML}
						</div>`
    }

    if (button.expansion) {
        let expansionLinks = renderExpansionLinks(button.expansion);
        expansion = `<div class="link-button__expansion expansion">
							${expansionLinks.join('')}
						</div>`
    }

    if (button.text.length > 15) textSize = ` link-button__text_smaller`;

    return `<div class="link-button__button" data-type="${buttonType}"${dataAtr}>
                <div class="overlay"></div>
                <div class="link-button__over-plate"></div>
                    ${media}
                <span class="link-button__text${textSize}">${button.text}</span>
            </div>
            ${expansion}`
}

const expansionButtonClickHandler = function (ev) {
    let el = ev.currentTarget;
    
    if (el.dataset.type === 'linked') {
        window.open(el.dataset.link, '_blank');
    }
}

const buttonClickHandler = function (e) {
    const el = e.currentTarget;
    const expansion = el.querySelector('.expansion');
    let linkedButton = el.querySelector('.link-button__button');

    if (expansion) {
        let expansionButtonsDOM = expansion.querySelectorAll('.expansion__link');

        if (expansion.classList.contains('expansion_open')) {
            expansion.classList.remove('expansion_open');
            expansionButtonsDOM.forEach(btn => {
                btn.removeEventListener('click', expansionButtonClickHandler);
            })
        } else {
            expansion.classList.add('expansion_open');
            
            expansionButtonsDOM.forEach(btn => {
                btn.addEventListener('click', expansionButtonClickHandler);
            });
        }  
    }
    
    if (linkedButton.dataset.type === 'linked') {
        window.open(linkedButton.dataset.link, '_blank');
    }
}

const init = function(el) {
    // create all buttons and render;
    let buttonsArr = [];

    buttons.forEach(button => {
        let buttonInner = renderButtonInner(button);
        let buttonLayout = `<div class="link-button">
						${buttonInner}
                    </div>`;
        buttonsArr.push(buttonLayout);
    });

    if (buttons.length % 2) {
        buttonsArr.push(`<div class="link-button"></div>`);
    }

    el.innerHTML = buttonsArr.join('');

    let buttonsDOM = document.querySelectorAll('.link-button');

    buttonsDOM.forEach(btn => {
        btn.addEventListener('click', buttonClickHandler);
    });
}
const contentBox = document.querySelector('.content');
init(contentBox);
